// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bestill-js": () => import("./../../../src/pages/bestill.js" /* webpackChunkName: "component---src-pages-bestill-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-produkter-js": () => import("./../../../src/pages/produkter.js" /* webpackChunkName: "component---src-pages-produkter-js" */),
  "component---src-pages-registrer-js": () => import("./../../../src/pages/registrer.js" /* webpackChunkName: "component---src-pages-registrer-js" */),
  "component---src-pages-spons-js": () => import("./../../../src/pages/spons.js" /* webpackChunkName: "component---src-pages-spons-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

